
import { defineComponent } from "vue";
import GoBackButton from "@/components/GoBackButton.vue";
import ComponentPostForm from "@/components/PostForm.vue";
import { Toast } from "@/lib/toast";
import { useRouter } from "vue-router";
import { userStore } from "@/store/user";
import { PostForm } from "@/models/post";
import { apiPost } from "@/lib/api_post";
export default defineComponent({
  name: "ViewPostNew",
  components: { GoBackButton, ComponentPostForm },
  setup() {
    const router = useRouter();
    const onSubmit = async (postForm: PostForm) => {
      console.log("onSubmit: ", postForm);
      try {
        const res = await apiPost.createPost(
          {
            postDetail: postForm.toPostDetail(),
          },
          userStore.ginToken.value
        );
        Toast.info("通告建立成功");
        router.push({ name: "postDetail", params: { id: res.getPostId() } });
      } catch (error) {
        Toast.info("通告建立失敗");
      }
    };

    return { onSubmit };
  },
});
